<template>
  <section>
    <v-card>
      <v-card-text align="center">
        <div>SG Covid19 Infection ratio</div>
        <p v-if="isLoading">Loading...</p>
        <p v-if="!isLoading" class="text-h4 text--primary">
          {{ latestRatio }}
        </p>
      </v-card-text>

      <div></div>
    </v-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      latestRatio: 0,
      results: [],
      isLoading: false
    };
  },
  methods: {
    loadData() {
      this.isLoading = true;
      fetch(
        "https://data.gov.sg/api/action/datastore_search?resource_id=99334c54-479f-472d-a6f5-fe38d2f9b1aa"
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          this.isLoading = false;
          this.latestRatio =
            data.result.records[
              data.result.records.length - 1
            ].ratio_comm_cases_pw_over_wb;
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style>
</style>